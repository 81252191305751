//library
import { useCallback, useMemo } from "react";

//hooks
import useTemplateContext from "hooks/use-template-context";

//dto
import AppFooterDto from "dto/components/app-footer/app-footer-dto";
import { SocialLinksDto } from "dto/pages/page-career-dto";

//components
import FooterLink from "components/app-footer/footer-link";
import FooterAddress from "components/app-footer/footer-address";

//assets
import twitterIcon from "assets/images/components/app-footer/twitter-icon.svg";
import facebookIcon from "assets/images/components/app-footer/facebook-icon.svg";
import linkedinIcon from "assets/images/components/app-footer/linkedin-icon.svg";

const AppFooter = () => {
	const { getFilteredResources } = useTemplateContext();

	const footerPage = useMemo(() => getFilteredResources("page.footer"), [getFilteredResources]);
	const getPageData = useCallback((key: string) => footerPage?.find((value) => value.key === key)?.value?.toString() ?? "", [footerPage]);
	const getPageLabel = useCallback((key: string) => footerPage?.find((value) => value.key === key)?.label?.toString() ?? "", [footerPage]);
	const footerLogo = useMemo(() => getPageData("page.footer.logo"), [getPageData]);
	const footerDisclaimer = useMemo(() => getPageData("page.footer.disclaimer"), [getPageData]);
	const emailHeader = useMemo(() => getPageData("page.footer.email.header"), [getPageData]);
	const emailDescription = useMemo(() => getPageData("page.footer.email.description"), [getPageData]);
	const connectHeader = useMemo(() => getPageData("page.footer.connect.header"), [getPageData]);
	const footerCertificate = useMemo(() => getPageData("page.footer.certificate"), [getPageData]);
	const footerCertificateLabel = useMemo(() => getPageLabel("page.footer.certificate"), [getPageLabel]);
	const termsOfUse = useMemo(() => getPageData("page.footer.termsOfUse"), [getPageData]);
	const privacyPolicy = useMemo(() => getPageData("page.footer.privacyPolicy"), [getPageData]);
	const cookiePolicy = useMemo(() => getPageData("page.footer.cookiePolicy"), [getPageData]);
	const footerConnect = useMemo(() => footerPage?.find((value) => value.key === "page.footer.connect")?.value as SocialLinksDto, [footerPage]);
	const branches = useMemo(() => {
		const rawBranches = footerPage?.find((value) => value.key === "page.footer.branches")?.value as AppFooterDto[];

		return [...rawBranches].sort((a, b) => (b.isHeadquarter ? 1 : 0) - (a.isHeadquarter ? 1 : 0));
	}, [footerPage]);

	return (
		<div className="app-footer">
			<div className="footer">
				{footerLogo && (
					<div className="footer__logo">
						<img src={footerLogo} alt="" className="footer__logo--img" />
					</div>
				)}

				<div className="footer__wrapper">
					{branches?.length > 0 &&
						branches.map((o, i) => (
							<FooterAddress key={i} title={o.country} isHeadquarter={o.isHeadquarter || false}>
								<div className="footer-address__description">
									<p className="footer-address__address">
										<span>{o.address1}</span>

										{o.address2 && <span> {o.address2}</span>}

										{o.address3 && <span> {o.address3}</span>}

										{o.postcode && <span> {o.postcode}</span>}

										{o.region && <span> {o.region}</span>}

										{o.state && <span> {o.state}</span>}

										{o.country && <span> {o.country}</span>}
									</p>
								</div>
							</FooterAddress>
						))}

					<FooterAddress title={emailHeader} description={emailDescription} />

					<FooterAddress title={connectHeader}>
						<div className="footer__cta-wrapper">
							<div className="footer__media">
								{footerConnect?.linkedin && <FooterLink url={footerConnect?.linkedin} iconUrl={linkedinIcon} label="LinkedIn" />}

								{footerConnect?.facebook && <FooterLink url={footerConnect?.facebook} iconUrl={facebookIcon} label="Facebook" />}

								{footerConnect?.twitter && <FooterLink url={footerConnect?.twitter} iconUrl={twitterIcon} label="Twitter" />}
							</div>

							{footerCertificate && (
								<div className="footer__cta-wrapper">
									<p className="footer__cta-wrapper--text">{footerCertificateLabel || "Certificate By"}</p>

									<img className="footer__img" src={footerCertificate} alt="" />
								</div>
							)}
						</div>
					</FooterAddress>
				</div>

				<div className="footer__divider" />

				<div className="footer__section">
					{/*prettier-ignore*/}
					<p className="footer__text">Copyright © {new Date().getFullYear()} {footerDisclaimer}</p>

					<div className="footer__navigation">
						{termsOfUse && <FooterLink url={termsOfUse} label="Terms of Use" isTnc />}

						{privacyPolicy && <FooterLink url={privacyPolicy} label="Privacy Policy" isTnc />}

						{cookiePolicy && <FooterLink url={cookiePolicy} label="Cookie Policy" isTnc />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppFooter;
