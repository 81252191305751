// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import debounce from "lodash/debounce";

// services
import api from "services/api";
import getPositionListing from "services/get-position-listing";
import getJobTypesListing from "services/get-job-types-listing";
import getAllVacanciesListing from "services/get-vacancies-listing";

// hooks
import useWindowSize from "hooks/use-window-size";
import useTemplateContext from "hooks/use-template-context";

// common
import serveRequestErrors from "common/serve-request-errors";
import formatAllToLowerCase from "common/format-all-to-lower-case";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { CareerDto, JoinTheTeamValueDto } from "dto/pages/page-career-dto";
import { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

// components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppFooter from "components/app-footer";
import AppNavbar from "components/app-navbar";
import AppTextarea from "components/app-textarea";
import AppSlideShow from "components/app-slideshow";
import HeaderCard from "components/app-header-card";
import AppPopupCard from "components/app-popup-card";
import AppPagination from "components/app-pagination";
import AppSelectInput from "components/app-select-input";
import AppPositionCard from "components/app-position-card";
import AppWorkHereCard from "components/app-work-here-card";
import AppFileUploadField from "components/app-file-upload-field";

// assets
import emailReceivedIcon from "assets/images/email-received.png";

const PageCareer = () => {
	const sizes = useWindowSize();
	const formRef = useRef<HTMLDivElement | null>(null);
	const modalRef = useRef<ImperativeHandleAppPopupCardDto>(null);
	const queryParams = useRef({ search: "", page: 0, size: 100 });

	const [currentPage, setCurrentPage] = useState(0);
	const [currentGrowPage, setCurrentGrowPage] = useState(0);
	const [selectedVacancy, setSelectedVacancy] = useState("");
	const [selectedJobType, setSelectedJobType] = useState("");
	const [vacancyData, setVacancyData] = useState<CareerDto[]>([]);
	const { getFilteredResources, activeTemplateData } = useTemplateContext();

	const isMobileView = useMemo(() => (sizes?.[0] <= 489 ? true : false), [sizes]);
	const pageConstant = useMemo(() => (isMobileView ? 3 : 9), [isMobileView]);
	const joinTheTeamPage = useMemo(() => getFilteredResources("page.joinTheTeam"), [getFilteredResources]);
	const getJoinTheTeamPageData = useCallback((key: any) => joinTheTeamPage?.find((value) => value.key === key)?.value?.toString() ?? "", [joinTheTeamPage]);
	const careerHeader = useMemo(() => getJoinTheTeamPageData("page.joinTheTeam.header"), [getJoinTheTeamPageData]);
	const careerDescription = useMemo(() => getJoinTheTeamPageData("page.joinTheTeam.description"), [getJoinTheTeamPageData]);
	const joinTheTeamContent = useMemo(() => (joinTheTeamPage?.find((value) => value.key === "page.joinTheTeam")?.value as JoinTheTeamValueDto[]) || [], [joinTheTeamPage]);
	const joinTheTeamMedia = useMemo(() => {
		const mediaValue = joinTheTeamPage?.find((value) => value.key === "page.joinTheTeam.media")?.value;

		if (Array.isArray(mediaValue)) {
			return mediaValue.map((url) => ({
				url: url as string,
			}));
		}

		return [];
	}, [joinTheTeamPage]);

	const careerPage = useMemo(() => getFilteredResources("page.career"), [getFilteredResources]);
	const getCareerPageData = useCallback((key: any) => careerPage?.find((value) => value.key === key)?.value?.toString() ?? "", [careerPage]);
	const careerCoverHeader = useMemo(() => getCareerPageData("page.career.cover.header"), [getCareerPageData]);
	const careerCover = useMemo(() => getCareerPageData("page.career.cover"), [getCareerPageData]);
	const notFoundHeader = useMemo(() => getCareerPageData("page.career.notFound.header"), [getCareerPageData]);
	const notFoundDescription = useMemo(() => getCareerPageData("page.career.notFound.description"), [getCareerPageData]);
	const careerFormHeader = useMemo(() => getCareerPageData("page.career.form.header"), [getCareerPageData]);
	const careerFormDescription = useMemo(() => getCareerPageData("page.career.form.description"), [getCareerPageData]);
	const careerFormName = useMemo(() => getCareerPageData("page.career.form.name"), [getCareerPageData]);
	const careerFormEmail = useMemo(() => getCareerPageData("page.career.form.email"), [getCareerPageData]);
	const careerFormPhone = useMemo(() => getCareerPageData("page.career.form.phone"), [getCareerPageData]);
	const careerFormMessage = useMemo(() => getCareerPageData("page.career.form.message"), [getCareerPageData]);
	const careerFormPosition = useMemo(() => getCareerPageData("page.career.form.position"), [getCareerPageData]);
	const careerFormResume = useMemo(() => getCareerPageData("page.career.form.resume"), [getCareerPageData]);
	const careerFormSubmit = useMemo(() => getCareerPageData("page.career.form.cta.label"), [getCareerPageData]);

	//prettier-ignore
	const filteredJobData = useMemo(() => vacancyData.filter((vacancy) => (!selectedVacancy || vacancy.employmentType === selectedVacancy) && (!selectedJobType || vacancy.title === selectedJobType)), [ selectedVacancy, selectedJobType, vacancyData]);
	const currentJobData = useMemo(() => filteredJobData.slice((currentPage + 1) * pageConstant - pageConstant, (currentPage + 1) * pageConstant), [filteredJobData, currentPage, pageConstant]);

	//prettier-ignore
	const totalPageNumber = useMemo(() => Math.ceil(filteredJobData.length / pageConstant), [filteredJobData.length, pageConstant]);
	const totalGrowPageNumber = useMemo(() => Math.ceil(joinTheTeamContent.length / pageConstant), [joinTheTeamContent.length, pageConstant]);
	const paginatedGrow = useMemo(() => joinTheTeamContent.slice(currentGrowPage * pageConstant, (currentGrowPage + 1) * pageConstant), [joinTheTeamContent, currentGrowPage, pageConstant]);

	const title = "Your application has been successfully submitted!";

	const validationSchema = yup.object({
		name: yup.string().required("This field is required"),
		email: yup.string().email("Please enter a valid email address").required("This field is required"),
		phone: yup
			.string()
			.matches(/^[0-9]+$/, "Please enter a valid phone number")
			.min(10, "Please enter a valid phone number")
			.required("This field is required"),
		roleType: yup.string().required("This field is required"),
		message: yup.string().required("This field is required"),
		file: yup
			.mixed()
			.test("fileSize", "Uploaded file cannot exceed 4 MB", (value) => !value || (value && value.size <= 4000000))
			.test(
				"fileFormat",
				"Unsupported Format",
				(value) =>
					!value ||
					(value &&
						["image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation"].includes(
							value.type
						))
			)
			.required("This field is required"),
	});

	//prettier-ignore
	const formik = useFormik({
		initialValues: { name: "", email: "", phone: "", roleType: "", message: "", file: null },
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (values, { resetForm }) => {
			const formData = new FormData();

			formData.append("name", values.name);
			formData.append("email", values.email);
			formData.append("phone", values.phone);
			formData.append("jobType", values.roleType);
			formData.append("message", values.message);

			if (values.file) {
				formData.append("resume", values.file);
			}

			try {
				await api.post.career.form(activeTemplateData, formData);

				resetForm();

				modalRef.current?.onHandleShow();
			} catch (unknown: unknown) {
				const error = unknown as Error | ErrorResponseDto;

				serveRequestErrors(error);
			}
		},
	});

	const validateFileField = debounce((validateField) => {
		validateField("file");
	}, 10);

	const onHandleGetVacancy = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 12, page: params.page };

			response = await api.get.career.hiringSearch(payload);

			setVacancyData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const onHandleVacancyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedVacancy(event.target.value);

		setCurrentPage(0);
	};

	const onHandleJobTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedJobType(event.target.value);

		setCurrentPage(0);
	};

	const onHandleGrowBack = useCallback(() => {
		if (currentGrowPage !== 0) setCurrentGrowPage((prev) => --prev);
	}, [currentGrowPage]);

	const onHandleGrowNext = useCallback(() => {
		if (currentGrowPage !== totalGrowPageNumber - 1) setCurrentGrowPage((prev) => ++prev);
	}, [currentGrowPage, totalGrowPageNumber]);

	const onHandleBack = useCallback(() => {
		if (currentPage !== 0) setCurrentPage((prev) => --prev);
	}, [currentPage]);

	const onHandleNext = useCallback(() => {
		if (currentPage !== totalPageNumber - 1) setCurrentPage((prev) => ++prev);
	}, [currentPage, totalPageNumber]);

	const onHandleApplyNow = (jobType: string) => {
		formik.setFieldValue("roleType", jobType);

		if (formRef.current) {
			const yOffset = -100;
			const scrollToValue = formRef.current?.getBoundingClientRect().top + window.scrollY + yOffset;

			window.scrollTo({ top: scrollToValue, behavior: "smooth" });
		}
	};

	useEffect(() => {
		onHandleGetVacancy();
	}, [onHandleGetVacancy]);

	return (
		<div className="page-career">
			<div className="career">
				<AppNavbar />

				<HeaderCard backgroundImage={careerCover} title={careerCoverHeader} />

				<div className="about-work">
					<p className="about-work__title">{careerHeader}</p>

					<p className="about-work__description">{careerDescription}</p>

					<div className="about-work__wrapper">
						{paginatedGrow.map((o, i) => (
							<AppWorkHereCard title={o.title} description={o.description} image={o.thumbnail} key={i} />
						))}
					</div>

					<div className="career__button-wrapper">
						{isMobileView && totalGrowPageNumber > 1 && <AppPagination totalPageNumber={totalGrowPageNumber} currentPage={currentGrowPage} onHandleNext={onHandleGrowNext} onHandleBack={onHandleGrowBack} />}
					</div>
				</div>

				{joinTheTeamMedia.length > 0 && (
					<AppSlideShow sliderBackgroundColor="#fff">
						{joinTheTeamMedia.map((item, index) => (
							<div key={index}>
								<img className="marquee-career__img" src={item.url} alt="" />
							</div>
						))}
					</AppSlideShow>
				)}

				<div className="vacancy">
					<p className="vacancy__title">{notFoundHeader}</p>

					<p className="vacancy__description">{notFoundDescription}</p>

					<div className="vacancy__selected">
						<div className="vacancy__option">
							<AppSelectInput name="vacancy" placeholder="All Vacancies" value={selectedVacancy} onChange={onHandleVacancyChange} loadOptions={getAllVacanciesListing} customWidth />

							<AppSelectInput name="jobTypes" placeholder="All Job Types" value={selectedJobType} onChange={onHandleJobTypeChange} loadOptions={getJobTypesListing} customWidth />
						</div>

						{!isMobileView && totalPageNumber > 1 && vacancyData.length > pageConstant && (
							<AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={onHandleNext} onHandleBack={onHandleBack} />
						)}
					</div>

					<div className="vacancy__wrapper">
						{currentJobData.map((o, i) => {
							return <AppPositionCard title={o.title} description={o.description} jobType={o.employmentType} document={o.document} key={i} onHandleApplyNow={() => onHandleApplyNow(o.id)} />;
						})}
					</div>

					<div className="career__button-wrapper">
						{isMobileView && totalPageNumber > 1 && vacancyData.length > pageConstant && (
							<AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={onHandleNext} onHandleBack={onHandleBack} />
						)}
					</div>
				</div>

				<div className="form-section" ref={formRef}>
					<p className="form-section__title">{careerFormHeader}</p>

					<p className="form-section__description">{careerFormDescription}</p>

					<form className="form-section__form" onSubmit={formik.handleSubmit} noValidate>
						<section className="form-section__form-container">
							{/*prettier-ignore*/}
							<AppInput required type="text" name="name" label={careerFormName} placeholder={`Enter ${formatAllToLowerCase(careerFormName)}`} value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} onChange={formik.handleChange} />

							{/*prettier-ignore*/}
							<AppInput required name="email" placeholder={`Enter your ${formatAllToLowerCase(careerFormEmail)}`} type="email" label={careerFormEmail} value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange} />

							{/*prettier-ignore*/}
							<AppInput required type="number" name="phone" label={careerFormPhone} placeholder={`Enter ${formatAllToLowerCase(careerFormPhone)}`} value={formik.values.phone} touched={formik.touched.phone} error={formik.errors.phone} onChange={formik.handleChange} />

							{/*prettier-ignore*/}
							<AppSelectInput required name="roleType" label={careerFormPosition} placeholder={`Select ${formatAllToLowerCase(careerFormPosition)}`} value={formik.values.roleType} touched={formik.touched.roleType} error={formik.errors.roleType} loadOptions={getPositionListing} onChange={formik.handleChange} />

							{/*prettier-ignore*/}
							<AppTextarea required id="message" name="message" label={careerFormMessage} placeholder={"Tell us about yourself"} value={formik.values.message} touched={formik.touched.message} error={formik.errors.message} onChange={formik.handleChange} customHeight />

							{/*prettier-ignore*/}
							<AppFileUploadField required id="file" name="file" label={careerFormResume} value={formik.values.file} touched={formik.touched.file} error={formik.errors.file} onChange={(file) => {formik.setFieldValue("file", file); validateFileField(formik.validateField);}} maxSize={4} />
						</section>

						<div className="submit-container">{careerFormSubmit && <AppButton type="submit" id="submit" label={careerFormSubmit} className="button-section__button" disabled={formik.isSubmitting} />}</div>
					</form>
				</div>

				<AppFooter />

				<AppPopupCard ref={modalRef} image={emailReceivedIcon} title={title} />
			</div>
		</div>
	);
};

export default PageCareer;
