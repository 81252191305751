//library
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Backdrop } from "@mui/material";

// pathnames
import pathnames from "routes/pathnames";

// common
import classNames from "common/class-names";

// hooks
import useTemplateContext from "hooks/use-template-context";

// dto
import { SocialLinksDto } from "dto/pages/page-career-dto";
import AppNavbarDto, { NavigationDto } from "dto/components/app-navbar-dto";

//assets
import FooterLink from "./app-footer/footer-link";
import FooterAddress from "./app-footer/footer-address";
import MobileMenuIcon from "assets/images/mobile-menu-icon.svg";
import WhiteCloseIcon from "assets/images/white-close-icon.svg";
import twitterIcon from "assets/images/components/app-footer/twitter-icon.svg";
import facebookIcon from "assets/images/components/app-footer/facebook-icon.svg";
import linkedinIcon from "assets/images/components/app-footer/linkedin-icon.svg";

const AppNavbar = ({ className }: AppNavbarDto) => {
	const location = useLocation();
	const [open, setOpen] = React.useState(false);
	const { getFilteredResources } = useTemplateContext();
	const footerPage = useMemo(() => getFilteredResources("page.footer"), [getFilteredResources]);
	const getPageData = useCallback((key: string) => footerPage?.find((value) => value.key === key)?.value?.toString() ?? "", [footerPage]);
	const navigationPage = useMemo(() => getFilteredResources("page.header"), [getFilteredResources]);
	const navigationList = useMemo(() => navigationPage?.find((value) => value.key === "page.header.navigation")?.value as NavigationDto[], [navigationPage]);
	const logo = useMemo(() => navigationPage?.find((value) => value.key === "page.header.logo")?.value as string, [navigationPage]);
	const connectHeader = useMemo(() => getPageData("page.footer.connect.header"), [getPageData]);
	const footerConnect = useMemo(() => footerPage?.find((value) => value.key === "page.footer.connect")?.value as SocialLinksDto, [footerPage]);

	//prettier-ignore
	const navbarClassName = useMemo(() => (classNames({
		"app-navbar": true,
        ...(className && { [className]: true })
	})), [className]);

	const toggleSidebar = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	useEffect(() => {
		document.body.style.overflow = open ? "hidden" : "auto";
	}, [open]);

	return (
		<div className={navbarClassName}>
			<div className="navbar">
				<div className="navbar__wrapper">
					<div className="navbar__container">
						<Link to={pathnames.PageHome}>
							<img src={logo} alt="logo" />
						</Link>

						<div className="nav-menu">
							{navigationList?.map((o, i) => {
								const isActive = location.pathname === o.pathName || (location.pathname.startsWith("/article/") && o.pathName === "/all-articles");

								return (
									<Link className="nav-menu__item" to={o.pathName} key={i}>
										<p className={`nav-menu__label ${isActive ? "nav-menu__label--active" : ""}`}>{o.title}</p>
									</Link>
								);
							})}
						</div>
						<img className="sidebar-menu-btn" src={MobileMenuIcon} alt="icon" onClick={toggleSidebar(true)} />
						<aside className={`sidebar ${open ? "sidebar-open" : ""}`} id="sidebar" aria-label="Sidenav">
							<div className="sidebar-content">
								<div className="sidebar-header">
									<Link to={pathnames.PageHome}>
										<img className="sidebar-logo" src={logo} alt="logo" />
									</Link>
									<img className="sidebar-close-btn" src={WhiteCloseIcon} alt="icon" onClick={toggleSidebar(false)} />
								</div>
								<ul className="sidebar-nav">
									{navigationList?.map((o, i) => {
										const isActive = location.pathname === o.pathName || (location.pathname.startsWith("/article/") && o.pathName === "/all-articles");

										return (
											<li className="nav-item" key={i}>
												<Link className="nav-link" to={o.pathName} key={i}>
													<p className={`nav-menu__label ${isActive ? "nav-menu__label--active" : ""}`}>{o.title}</p>
												</Link>
											</li>
										);
									})}
								</ul>
								<div className="sidebar-footer">
									<div className="sidebar-footer-content">
										<FooterAddress title={connectHeader}>
											<div className="sidebar-footer-social">
												{footerConnect?.linkedin && <FooterLink url={footerConnect?.linkedin} iconUrl={linkedinIcon} label="LinkedIn" />}

												{footerConnect?.facebook && <FooterLink url={footerConnect?.facebook} iconUrl={facebookIcon} label="Facebook" />}

												{footerConnect?.twitter && <FooterLink url={footerConnect?.twitter} iconUrl={twitterIcon} label="Twitter" />}
											</div>
										</FooterAddress>
									</div>
								</div>
							</div>
						</aside>
					</div>
				</div>
			</div>
			<Backdrop open={open} onClick={toggleSidebar(false)}></Backdrop>
		</div>
	);
};

export default memo(AppNavbar);
