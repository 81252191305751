import { useCallback } from "react";
import { AppPositionCardDto } from "dto/components/app-position-card-dto";

import AppButton from "components/app-button";

const AppPositionCard = ({ document, title, jobType, description, onHandleApplyNow }: AppPositionCardDto) => {
	const handlePreview = useCallback(() => {
		window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(document)}`, "_blank");
	}, [document]);

	return (
		<div className="app-position-card">
			<div className="position-card">
				<p className="position-card__title">{title}</p>

				<p className="position-card__description">
					Job Types: <span className="job-type">{jobType}</span>
				</p>

				<p className="position-card__description">{description}</p>

				<div className="position-card__section">
					<AppButton className="apply-button" label="Apply Now" onClick={() => onHandleApplyNow(title, jobType)} />

					{document && <AppButton className="position-card__btn" label="Job Details" onClick={handlePreview} tertiary />}
				</div>
			</div>
		</div>
	);
};

export default AppPositionCard;
